<template>
  <div id="app" class="max-w-4xl mx-auto p-4 sm:p-8">
    <router-link to="/" class="back-button">
      <i class="fas fa-arrow-left"></i> Назад
    </router-link>
    <h1 class="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6">Калькулятор дохода курьера</h1>
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4 sm:mb-6">
      <div>
        <label class="block mb-2">Выбери регион</label>
        <select v-model="selectedRegion" class="custom-select" aria-label="Регион">
          <option value="moscow">Москва</option>
          <option value="moscow_region">Московская область</option>
          <option value="saint_petersburg">Санкт-Петербург</option>
          <option value="altai_krai">Алтайский край</option>
          <option value="amur_oblast">Амурская область</option>
          <option value="arkhangelsk_oblast">Архангельская область</option>
          <option value="astrakhan_oblast">Астраханская область</option>
          <option value="belgorod_oblast">Белгородская область</option>
          <option value="bryansk_oblast">Брянская область</option>
          <option value="vladimir_oblast">Владимирская область</option>
          <option value="volgograd_oblast">Волгоградская область</option>
          <option value="vologda_oblast">Вологодская область</option>
          <option value="voronezh_oblast">Воронежская область</option>
          <option value="jewish_autonomous_oblast">Еврейская автономная область</option>
          <option value="zabaykalsky_krai">Забайкальский край</option>
          <option value="ivanovo_oblast">Ивановская область</option>
          <option value="irkutsk_oblast">Иркутская область</option>
          <option value="kabardino_balkar_republic">Кабардино-Балкарская Республика</option>
          <option value="kaliningrad_oblast">Калининградская область</option>
          <option value="kaluga_oblast">Калужская область</option>
          <option value="kamchatka_krai">Камчатский край</option>
          <option value="karachay_cherkess_republic">Карачаево-Черкесская Республика</option>
          <option value="kemerovo_oblast">Кемеровская область</option>
          <option value="kirov_oblast">Кировская область</option>
          <option value="kostroma_oblast">Костромская область</option>
          <option value="krasnodar_krai">Краснодарский край</option>
          <option value="krasnoyarsk_krai">Красноярский край</option>
          <option value="kurgan_oblast">Курганская область</option>
          <option value="kursk_oblast">Курская область</option>
          <option value="leningrad_oblast">Ленинградская область</option>
          <option value="lipetsk_oblast">Липецкая область</option>
          <option value="magadan_oblast">Магаданская область</option>
          <option value="murmansk_oblast">Мурманская область</option>
          <option value="nenets_autonomous_okrug">Ненецкий автономный округ</option>
          <option value="nizhny_novgorod_oblast">Нижегородская область</option>
          <option value="novgorod_oblast">Новгородская область</option>
          <option value="novosibirsk_oblast">Новосибирская область</option>
          <option value="omsk_oblast">Омская область</option>
          <option value="orenburg_oblast">Оренбургская область</option>
          <option value="orlov_oblast">Орловская область</option>
          <option value="penza_oblast">Пензенская область</option>
          <option value="perm_krai">Пермский край</option>
          <option value="primorsky_krai">Приморский край</option>
          <option value="pskov_oblast">Псковская область</option>
          <option value="adygea_republic">Республика Адыгея</option>
          <option value="altai_republic">Республика Алтай</option>
          <option value="bashkortostan_republic">Республика Башкортостан</option>
          <option value="buriatia_republic">Республика Бурятия</option>
          <option value="dagestan_republic">Республика Дагестан</option>
          <option value="ingushetia_republic">Республика Ингушетия</option>
          <option value="kalmykia_republic">Республика Калмыкия</option>
          <option value="karelia_republic">Республика Карелия</option>
          <option value="komi_republic">Республика Коми</option>
          <option value="crimea_republic">Республика Крым</option>
          <option value="mari_el_republic">Республика Марий Эл</option>
          <option value="mordovia_republic">Республика Мордовия</option>
          <option value="saha_republic">Республика Саха (Якутия)</option>
          <option value="north_ossetia_republic">Республика Северная Осетия — Алания</option>
          <option value="tatarstan_republic">Республика Татарстан</option>
          <option value="tuva_republic">Республика Тыва</option>
          <option value="khakassia_republic">Республика Хакасия</option>
          <option value="rostov_oblast">Ростовская область</option>
          <option value="ryazan_oblast">Рязанская область</option>
          <option value="samara_oblast">Самарская область</option>
          <option value="saratov_oblast">Саратовская область</option>
          <option value="sakhalin_oblast">Сахалинская область</option>
          <option value="sverdlovsk_oblast">Свердловская область</option>
          <option value="sevastopol">Севастополь</option>
          <option value="smolensk_oblast">Смоленская область</option>
          <option value="stavropol_krai">Ставропольский край</option>
          <option value="tambov_oblast">Тамбовская область</option>
          <option value="tver_oblast">Тверская область</option>
          <option value="tomsk_oblast">Томская область</option>
          <option value="tula_oblast">Тульская область</option>
          <option value="tyumen_oblast">Тюменская область</option>
          <option value="udmurt_republic">Удмуртская Республика</option>
          <option value="uliyanovsk_oblast">Ульяновская область</option>
          <option value="khabarovsk_krai">Хабаровский край</option>
          <option value="khanty_mansi_autonomous_okrug">Ханты-Мансийский автономный округ — Югра</option>
          <option value="chelyabinsk_oblast">Челябинская область</option>
          <option value="chechen_republic">Чеченская Республика</option>
          <option value="chuvash_republic">Чувашская Республика</option>
          <option value="chukchi_autonomous_okrug">Чукотский автономный округ</option>
          <option value="yamalo_nenets_autonomous_okrug">Ямало-Ненецкий автономный округ</option>
          <option value="yaroslavl_oblast">Ярославская область</option>          
        </select>
      </div>
      <div>
        <label class="block mb-2">Выбери вещество</label>
        <select v-model="selectedShippingType" class="custom-select" aria-label="Вещество">
          <option value="cannabis">Гашиш</option>
          <option value="mephedrone">Мефедрон</option>
          <option value="α-PVP">Альфа-ПВП</option>
        </select>
      </div>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mb-4 sm:mb-6">
      <div :class="['custom-card', { 'active': selectedType === 'city_zone' }, 'card-select']" @click="selectType('city_zone')">
        <h2 class="font-bold mb-2">Магнит, тайник</h2>
        <p class="text-sm">Эти клады создаются в городской зоне. Магнит цепляют на металлические конструкции, а тайник прячут в укромное место.</p>
      </div>
      <div :class="['custom-card', { 'active': selectedType === 'suburb_zone'}, 'card-select']" @click="selectType('suburb_zone')">
        <h2 class="font-bold mb-2">Прикоп</h2>
        <p class="text-sm">Эти клады создаются в пригородной зоне. Прикоп закапывают в землю в немноголюдных местах, например, в парках или лесах.</p>
      </div>
    </div>
    <div class="mb-4 sm:mb-6">
      <label class="block mb-2">Сколько дней в неделю планируешь работать</label>
      <div class="flex items-center">
        <input type="range" min="1" max="7" v-model="daysPerWeek" class="custom-slider" aria-label="Количество дней в неделю">
        <span class="ml-4">{{ daysPerWeek }}</span>
      </div>
    </div>
    <div class="mb-4 sm:mb-6">
      <label class="block mb-2">Количество кладов в день</label>
      <div class="flex items-center">
        <input type="range" min="1" max="100" v-model="cachesPerDay" class="custom-slider" aria-label="Количество кладов в день">
        <span class="ml-4">{{ cachesPerDay }}</span>
      </div>
    </div>
    <div class="flex items-center mb-4 sm:mb-6">
      <input type="checkbox" id="weight" v-model="isSelfPacking" class="custom-checkbox mr-2">
      <label for="weight" class="flex items-center">
        <span class="ml-2">Сам займусь фасовкой</span>
      </label>
    </div>
    <div class="custom-card income-card mb-4 sm:mb-6">
      <h2 class="text-xl sm:text-2xl font-bold mb-4">Возможный доход</h2>
      <div class="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
        <div class="mb-2 sm:mb-0 flex flex-wrap items-baseline">
            <span class="text-4xl md:text-6xl font-bold">{{ monthlyIncome }} ₽</span>
            <span class="text-base md:text-md ml-2 whitespace-nowrap">за месяц</span>
        </div>
        <div class="flex flex-wrap items-baseline">
            <span class="text-4xl md:text-6xl font-bold">{{ weeklyIncome }} ₽</span>
            <span class="text-base md:text-md ml-2 whitespace-nowrap">за неделю</span>
        </div>
      </div>
      <p class="mb-4">или выбери желаемый доход</p>
      <div class="income-options-container">
        <button 
          v-for="income in incomeOptions" 
          :key="income" 
          @click="selectIncome(income)" 
          :class="['income-option', { 'active': selectedIncome === income }]">
          {{ income }} ₽
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, computed, watch, onMounted } from 'vue'

  export default {
    setup() {
      const isDarkTheme = ref(false)

      const setTheme = (isDark) => {
        isDarkTheme.value = isDark
        document.documentElement.classList.toggle('dark', isDark)
      }

      const checkSystemThemePreference = () => {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
          setTheme(true)
        } else {
          setTheme(false)
        }
      }

      onMounted(() => {
        checkSystemThemePreference()
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', checkSystemThemePreference)
      })

      const selectedRegion = ref('moscow')
      const selectedShippingType = ref('cannabis')
      const selectedType = ref('city_zone')
      const daysPerWeek = ref(5)
      const cachesPerDay = ref(45)
      const isSelfPacking = ref(true)
      const selectedIncome = ref(null)
      const incomeOptions = ref(['100 000', '250 000', '400 000', '550 000', '700 000', '850 000'])
      const isAdjustingSliders = ref(false)

      const basePrice = computed(() => {
        let price = 900
        if (selectedRegion.value === 'arkhangelsk_oblast') {
          if (selectedShippingType.value === 'cannabis') price = 900
          else if (selectedShippingType.value === 'mephedrone') price = 900
          else if (selectedShippingType.value === 'α-PVP') price = 900
        }
        if (isSelfPacking.value) price += 100
        if (selectedType.value === 'city_zone' && isSelfPacking.value) price += 100
        
        let cachesPerMonth = cachesPerDay.value * daysPerWeek.value * 4.2 
        if (cachesPerMonth > 1500) price += 200
        else if (cachesPerMonth > 1000) price += 175
        else if (cachesPerMonth > 750) price += 150
        else if (cachesPerMonth > 500) price += 125
        else if (cachesPerMonth > 250) price += 100
        else if (cachesPerMonth > 150) price += 75
        else if (cachesPerMonth > 100) price += 50

        return price
      })

      const monthlyIncome = computed(() => {
        return (cachesPerDay.value * daysPerWeek.value * 4 * basePrice.value).toLocaleString('ru-RU')
      })

      const weeklyIncome = computed(() => {
        return (cachesPerDay.value * daysPerWeek.value * basePrice.value).toLocaleString('ru-RU')
      })

      const selectType = (type) => {
        selectedType.value = type
      }

      const selectIncome = (income) => {
        selectedIncome.value = income
        isAdjustingSliders.value = true
        adjustSlidersForIncome(income)
        setTimeout(() => {
          isAdjustingSliders.value = false
        }, 0)
      }

      const adjustSlidersForIncome = (income) => {
        const incomeValue = parseInt(income.replace(/\s/g, ''))
        isSelfPacking.value = true
        selectedType.value = 'city_zone'
        switch (incomeValue) {
          case 100000:
            daysPerWeek.value = 2
            cachesPerDay.value = 12
            break
          case 250000:
            daysPerWeek.value = 3
            cachesPerDay.value = 18
            break
          case 400000:
            daysPerWeek.value = 3
            cachesPerDay.value = 28
            break
          case 550000:
            daysPerWeek.value = 4
            cachesPerDay.value = 29
            break
          case 700000:
            daysPerWeek.value = 4
            cachesPerDay.value = 36
            break
          case 850000:
            daysPerWeek.value = 5
            cachesPerDay.value = 35
            break
        }
      }

      watch([daysPerWeek, cachesPerDay], () => {
        if (!isAdjustingSliders.value) {
          selectedIncome.value = null
        }
      })

      return {
        selectedRegion,
        selectedShippingType,
        selectedType,
        daysPerWeek,
        cachesPerDay,
        isSelfPacking,
        selectedIncome,
        incomeOptions,
        basePrice,
        monthlyIncome,
        weeklyIncome,
        selectType,
        selectIncome
      }
    }
  }
</script>

<style scoped>
  @media (max-width: 960px) {
    .income-option {
      flex-basis: calc(33.333% - 10px);
    }
  }
  @media (max-width: 768px) {
    .custom-card.income-card {
      margin-top: 2rem;
      margin-bottom: 2rem;
      padding: 1.5rem;
    }
    .income-option {
      flex-basis: calc(50% - 10px);
    }
  }
  @media (max-width: 320spx) {
    .income-option {
      flex-basis: 100%;
    }
  }
</style>