<template>
  <div :class="['app-container', { dark: isDarkTheme }]">
    <div class="logo-container">
      <img src="../../images/logo2.png" alt="СИНДИКАТ логотип" class="logo">
    </div>
    
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    
    <footer class="footer mt-8">
      <img src="../../images/icon.png" alt="Иконка СИНДИКАТ" class="icon">
    </footer>
  </div>
</template>

<script>
  import { ref, onMounted } from 'vue'

  export default {
    name: 'App',
    setup() {
      const isDarkTheme = ref(false)

      const setTheme = (isDark) => {
        isDarkTheme.value = isDark
        document.documentElement.classList.toggle('dark', isDark)
      }

      const checkSystemThemePreference = () => {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
          setTheme(true)
        } else {
          setTheme(false)
        }
      }

      onMounted(() => {
        checkSystemThemePreference()
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', checkSystemThemePreference)
      })

      return {
        isDarkTheme
      }
    }
  }
</script>

<style>
  .app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: var(--card-bg-color);
    color: var(--text-color);
    transition: background-color 0.3s, color 0.3s;
  }

  .logo-container {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo {
    max-width: 100%;
    height: auto;
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    margin-top: auto;
  }

  .icon {
    width: 150px;
    height: 150px;
    object-fit: contain;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from, .fade-leave-to {
    opacity: 0;
  }

  .fade-enter-to {
    opacity: 1;
  }

  .fade-leave-from {
    opacity: 1;
  }

  :root {
    --bg-color: #f5f5f5;
    --highlight-color: #B70101;
    --text-color: #000000;
    --card-bg-color: #ffffff;
    --hover-color: #ccc;
  }

  @media (prefers-color-scheme: dark) {
    :root {
      --bg-color: #1a1a1a;
      --highlight-color: #ff4040;
      --text-color: #ffffff;
      --card-bg-color: #2a2a2a;
      --hover-color: #494848;
    }
  }

  @font-face {
    font-family: 'Rockwell Nova';
    font-style: normal;
    font-weight: normal;
    src: local('Rockwell Nova'), url('/fonts/RockwellNova.woff') format('woff'),
    url("/fonts/RockwellNova.ttf");
  }
  @font-face {  
    font-family: 'IBM Plex Serif';
    font-style: normal;
    font-weight: normal;
    src: local('IBM Plex Serif'), url("/fonts/IBMPlexSerif-Medium-subset.ttf");
  }

  body {
    font-family: 'Rockwell Nova', 'IBM Plex Serif', 'Georgia', 'Times New Roman', 'Times', serif;
    background-color: var(--card-bg-color);
    color: var(--text-color);
    transition: background-color 0.3s, color 0.3s;
  }
  #app {
    border-radius: 15px;
    padding: 20px;
    margin: 0 auto;
  }
  .back-button {
    display: inline-flex;
    align-items: center;
    background-color: var(--bg-color);
    color: var(--text-color);
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 16px;
    text-decoration: none;
    transition: background-color 0.3s ease;
    margin-bottom: 20px;
  }
  .back-button:hover {
  background-color: var(--hover-color);
}
  .back-button i {
    margin-right: 12px;
  }
  .custom-select {
    background-color: var(--bg-color);
    border: none;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    font-size: 16px;
    color: var(--text-color);
  }
  .card-select {
    background-color: var(--bg-color);
    padding: 15px;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s;
    margin-bottom: 10px;
  }
  .card-select:hover {
    background-color: var(--hover-color);
  }
  .card-select.active {
    background-color: var(--highlight-color);
    color: #ffffff;
  }
  .custom-slider {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    background: var(--bg-color);
    outline: none;
    opacity: 1;
    transition: opacity .2s;
    border-radius: 5px;
  }
  .custom-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: var(--highlight-color);
    cursor: pointer;
    border-radius: 50%;
  }
  .custom-slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: var(--highlight-color);
    cursor: pointer;
    border-radius: 50%;
  }
  span.ml-4 {
    display: inline-block;
    width: 50px;
    text-align: right;
  }
  .custom-checkbox {
    appearance: none;
    background-color: var(--bg-color);
    border: 2px solid var(--bg-color);
    padding: 10px;
    border-radius: 5px;
    display: inline-block;
    position: relative;
    cursor: pointer;
  }
  .custom-checkbox:checked {
    background-color: var(--highlight-color);
    border-color: var(--highlight-color);
  }
  .custom-checkbox:checked::after {
    content: '\2713';
    font-size: 16px;
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .custom-card {
    background-color: var(--bg-color);
    color: var(--text-color);
    padding: 15px;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 10px;
  }
  .income-options-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
  }
  .income-option {
    flex-grow: 1;
    flex-basis: calc(16.666% - 10px);
    text-align: center;
    padding: 10px;
    background-color: var(--card-bg-color);
    color: var(--text-color);
    border-radius: 20px;
    transition: all 0.3s ease;
    cursor: pointer;
    white-space: nowrap;
  }
  .income-option:hover {
    background-color: var(--hover-color);
  }
  .income-option.active {
    background-color: var(--highlight-color);
    color: #ffffff;
  }
  .start-earning {
    display: inline-block;
    background-color: var(--highlight-color);
    color: #fff;
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  .start-earning:hover {
    background-color: #ff5c5c;
  }

  .logo-container {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logo {
    max-width: 100%;
    height: auto;
    max-height: 5000px;
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
  }
  .icon {
    width: 150px;
    height: 150px;
    object-fit: contain;
  }
</style>