<template>
  <div id="app" class="max-w-4xl mx-auto p-4 sm:p-8">
    <router-link to="/" class="back-button">
      <i class="fas fa-arrow-left"></i> Назад
    </router-link>
    <h1 class="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6">Калькулятор дохода складмена</h1>
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mb-4 sm:mb-6">
      <div :class="['custom-card', { 'active': selectedType === 'city_zone' }, 'card-select']" @click="selectType('city_zone')">
        <h2 class="font-bold mb-2">Магнит, тайник</h2>
        <p class="text-sm">Эти клады создаются в городской зоне. Магнит цепляют на металлические конструкции, а тайник прячут в укромное место.</p>
      </div>
      <div :class="['custom-card', { 'active': selectedType === 'suburb_zone'}, 'card-select']" @click="selectType('suburb_zone')">
        <h2 class="font-bold mb-2">Прикоп</h2>
        <p class="text-sm">Эти клады создаются в пригородной зоне. Прикоп закапывают в землю в немноголюдных местах, например, в парках или лесах.</p>
      </div>
    </div>
    <div class="mb-4 sm:mb-6">
      <label class="block mb-2">Сколько дней в неделю планируешь работать</label>
      <div class="flex items-center">
        <input type="range" min="1" max="7" v-model="daysPerWeek" class="custom-slider" aria-label="Количество дней в неделю">
        <span class="ml-4">{{ daysPerWeek }}</span>
      </div>
    </div>
    <div class="mb-4 sm:mb-6">
      <label class="block mb-2">Количество кладов в день</label>
      <div class="flex items-center">
        <input type="range" min="1" max="100" v-model="cachesPerDay" class="custom-slider" aria-label="Количество кладов в день">
        <span class="ml-4">{{ cachesPerDay }}</span>
      </div>
    </div>
    <div class="custom-card income-card mb-4 sm:mb-6">
      <h2 class="text-xl sm:text-2xl font-bold mb-4">Возможный доход</h2>
      <div class="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
        <div class="mb-2 sm:mb-0 flex flex-wrap items-baseline">
            <span class="text-4xl md:text-6xl font-bold">{{ monthlyIncome }} ₽</span>
            <span class="text-base md:text-md ml-2 whitespace-nowrap">за месяц</span>
        </div>
        <div class="flex flex-wrap items-baseline">
            <span class="text-4xl md:text-6xl font-bold">{{ weeklyIncome }} ₽</span>
            <span class="text-base md:text-md ml-2 whitespace-nowrap">за неделю</span>
        </div>
      </div>
      <p class="mb-4">или выбери желаемый доход</p>
      <div class="income-options-container">
        <button 
          v-for="income in incomeOptions" 
          :key="income" 
          @click="selectIncome(income)" 
          :class="['income-option', { 'active': selectedIncome === income }]">
          {{ income }} ₽
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, computed, watch, onMounted } from 'vue'

  export default {
    setup() {
      const isDarkTheme = ref(false)

      const setTheme = (isDark) => {
        isDarkTheme.value = isDark
        document.documentElement.classList.toggle('dark', isDark)
      }

      const checkSystemThemePreference = () => {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
          setTheme(true)
        } else {
          setTheme(false)
        }
      }

      onMounted(() => {
        checkSystemThemePreference()
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', checkSystemThemePreference)
      })

      const selectedRegion = ref('moscow')
      const selectedShippingType = ref('cannabis')
      const selectedType = ref('city_zone')
      const daysPerWeek = ref(5)
      const cachesPerDay = ref(45)
      const isSelfPacking = ref(true)
      const selectedIncome = ref(null)
      const incomeOptions = ref(['1 000 000', '1 500 000', '2 000 000', '2 500 000', '3 000 000', '3 500 000'])
      const isAdjustingSliders = ref(false)

      const basePrice = computed(() => {
        let price = 4000 + 125
        if (selectedType.value === 'city_zone') price += 100
        
        let cachesPerMonth = cachesPerDay.value * daysPerWeek.value * 4.2 
        if (cachesPerMonth > 1000) price += 175
        else if (cachesPerMonth > 750) price += 150
        else if (cachesPerMonth > 500) price += 125
        else if (cachesPerMonth > 250) price += 100
        else if (cachesPerMonth > 150) price += 75
        else if (cachesPerMonth > 100) price += 50

        return price
      })

      const monthlyIncome = computed(() => {
        return (cachesPerDay.value * daysPerWeek.value * 4 * basePrice.value).toLocaleString('ru-RU')
      })

      const weeklyIncome = computed(() => {
        return (cachesPerDay.value * daysPerWeek.value * basePrice.value).toLocaleString('ru-RU')
      })

      const selectType = (type) => {
        selectedType.value = type
      }

      const selectIncome = (income) => {
        selectedIncome.value = income
        isAdjustingSliders.value = true
        adjustSlidersForIncome(income)
        setTimeout(() => {
          isAdjustingSliders.value = false
        }, 0)
      }

      const adjustSlidersForIncome = (income) => {
        const incomeValue = parseInt(income.replace(/\s/g, ''))
        isSelfPacking.value = true
        selectedType.value = 'city_zone'
        switch (incomeValue) {
          case 1000000:
            daysPerWeek.value = 3
            cachesPerDay.value = 20
            break
          case 1500000:
            daysPerWeek.value = 4
            cachesPerDay.value = 22
            break
          case 2000000:
            daysPerWeek.value = 4
            cachesPerDay.value = 29
            break
          case 2500000:
            daysPerWeek.value = 4
            cachesPerDay.value = 36
            break
          case 3000000:
            daysPerWeek.value = 4
            cachesPerDay.value = 44
            break
          case 3500000:
            daysPerWeek.value = 4
            cachesPerDay.value = 50
            break
        }
      }

      watch([daysPerWeek, cachesPerDay], () => {
        if (!isAdjustingSliders.value) {
          selectedIncome.value = null
        }
      })

      return {
        selectedRegion,
        selectedShippingType,
        selectedType,
        daysPerWeek,
        cachesPerDay,
        isSelfPacking,
        selectedIncome,
        incomeOptions,
        basePrice,
        monthlyIncome,
        weeklyIncome,
        selectType,
        selectIncome
      }
    }
  }
</script>

<style scoped>
  @media (max-width: 960px) {
    .income-option {
      flex-basis: calc(33.333% - 10px);
    }
  }
  @media (max-width: 768px) {
    .custom-card.income-card {
      margin-top: 2rem;
      margin-bottom: 2rem;
      padding: 1.5rem;
    }
    .income-option {
      flex-basis: calc(50% - 10px);
    }
  }
  @media (max-width: 320spx) {
    .income-option {
      flex-basis: 100%;
    }
  }
</style>