<template> 
  <div id="app" class="max-w-4xl mx-auto p-4 sm:p-8">
    <router-link to="/" class="back-button">
      <i class="fas fa-arrow-left"></i> Назад
    </router-link>
    <h1 class="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6">Калькулятор дохода перевозчика</h1>

    <div class="mb-4 sm:mb-6">
      <label class="block mb-2">
        Выберите залог
        <span class="tooltip">
          <i class="fas fa-question-circle ml-1"></i>
          <span class="tooltiptext">Залог — это «страховка» для безопасности. Его потребуется внести перед первым рейсом</span>
        </span>
      </label>
      <select v-model="selectedDeposit" class="custom-select" @change="updateDepositLimits">
        <option v-for="(deposit, index) in deposits" :key="index" :value="deposit">
          {{ deposit.amount === null ? 'Выберите залог' : `${deposit.amount} руб.` }}
          <template v-if="deposit.amount !== null">
            ({{ deposit.weight }} кг, {{ deposit.caches }} кладов, {{ deposit.distance }} км)
          </template>
        </option>
      </select>
    </div>

    <div class="mb-4 sm:mb-6">
      <label class="block mb-2">
        Расстояние (км)
        <span class="tooltip">
          <i class="fas fa-question-circle ml-1"></i>
          <span class="tooltiptext">Продолжительность рейса в километрах</span>
        </span>
      </label>
      <div class="flex items-center">
        <input type="range" min="100" max="3000" step="50" v-model="distance" class="custom-slider" aria-label="Расстояние">
        <span class="ml-4">{{ distance }}</span>
      </div>
    </div>

    <div class="mb-4 sm:mb-6">
      <label class="block mb-2">
        Количество кладов
        <span class="tooltip">
          <i class="fas fa-question-circle ml-1"></i>
          <span class="tooltiptext">Количество кладов, которое потребуется сделать по завершению рейса</span>
        </span>
      </label>
      <div class="flex items-center">
        <input type="range" min="1" max="200" v-model="numberOfCaches" class="custom-slider" aria-label="Количество кладов">
        <span class="ml-4">{{ numberOfCaches }}</span>
      </div>
    </div>

    <div class="mb-4 sm:mb-6">
      <label class="block mb-2">
        Вес груза (кг)
        <span class="tooltip">
          <i class="fas fa-question-circle ml-1"></i>
          <span class="tooltiptext">Общий вес груза на рейс в килограммах</span>
        </span>
      </label>
      <div class="flex items-center">
        <input type="range" min="0.25" max="60" step="0.25" v-model="weight" class="custom-slider" aria-label="Вес груза">
        <span class="ml-4">{{ weight }}</span>
      </div>
    </div>

    <div class="mb-4 sm:mb-6">
      <label class="block mb-2">
        Длительность рейса (дни)
        <span class="tooltip">
          <i class="fas fa-question-circle ml-1"></i>
          <span class="tooltiptext">Продолжительность одного рейса в днях. Рекомендуемое значение устанавливатся автоматически</span>
        </span>
      </label>
      <div class="flex items-center">
        <input type="range" min="1" max="9" v-model="executionDays" class="custom-slider" aria-label="Дней на исполнение">
        <span class="ml-4">{{ executionDays }}</span>
      </div>
    </div>

    <div class="custom-card income-card mb-4 sm:mb-6">
      <h2 class="text-xl sm:text-2xl font-bold mb-4">Возможный доход</h2>
      <div class="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
        <div class="mb-2 sm:mb-0 flex flex-wrap items-baseline">
          <span class="text-4xl md:text-6xl font-bold">{{ monthlyIncome }} ₽</span>
          <span class="text-base md:text-md ml-2 whitespace-nowrap">за месяц</span>
        </div>
        <div class="flex flex-wrap items-baseline">
          <span class="text-4xl md:text-6xl font-bold">{{ tripIncome }} ₽</span>
          <span class="text-base md:text-md ml-2 whitespace-nowrap">за рейс</span>
        </div>
      </div>
      <p class="mb-4">или выбери желаемый доход</p>
      <div class="income-options-container">
        <button 
          v-for="income in incomeOptions" 
          :key="income" 
          @click="selectIncome(income)" 
          :class="['income-option', { 'active': selectedIncome === income }]">
          {{ income }} ₽
        </button>
      </div>
      <p class="mt-4 text-sm text-gray-600 italic">
        * 5% бонус за успешное завершение рейса
      </p>
    </div>
  </div>
</template>

<script>
  import { ref, computed, watch, nextTick } from 'vue';

  export default {
    setup() {
      const distance = ref(100);
      const numberOfCaches = ref(1);
      const weight = ref(0.25);
      const executionDays = ref(1);
      const selectedIncome = ref(null);
      const isAdjustingSliders = ref(false);

      const defaultDeposit = { amount: null, weight: null, caches: null, distance: null };

      const deposits = [
        defaultDeposit,
        { amount: 50000, weight: 0.25, caches: 8, distance: 500 },
        { amount: 100000, weight: 0.5, caches: 16, distance: 500 },
        { amount: 150000, weight: 1, caches: 24, distance: 500 },
        { amount: 300000, weight: 3, caches: 40, distance: 1000 },
        { amount: 500000, weight: 5, caches: 70, distance: 1000 },
        { amount: 1000000, weight: 10, caches: 110, distance: 2000 },
        { amount: 1500000, weight: 15, caches: 200, distance: 3000 },
      ];

      const selectedDeposit = ref(defaultDeposit);

      const updateDepositLimits = async () => {
        const deposit = selectedDeposit.value;
        if (deposit.amount !== null) {
          isAdjustingSliders.value = true;
          distance.value = deposit.distance;
          numberOfCaches.value = deposit.caches;
          weight.value = deposit.weight;
          executionDays.value = calculatedExecutionDays.value;
          selectedIncome.value = null;
          await nextTick();
          isAdjustingSliders.value = false;
        }
      };

      const incomeOptions = ['3 000 000', '4 000 000', '5 000 000', '6 000 000', '7 000 000', '8 000 000'];

      const incomeMappings = {
        3000000: { distance: 500, numberOfCaches: 15, weight: 5 },
        4000000: { distance: 800, numberOfCaches: 17, weight: 5 },
        5000000: { distance: 1500, numberOfCaches: 65, weight: 10 },
        6000000: { distance: 1600, numberOfCaches: 85, weight: 10 },
        7000000: { distance: 1800, numberOfCaches: 130, weight: 15 },
        8000000: { distance: 850, numberOfCaches: 70, weight: 15 }
      };

      const adjustSlidersForIncome = async (income) => {
        isAdjustingSliders.value = true;

        const incomeValue = parseInt(income.replace(/\s/g, ''));
        const mapping = incomeMappings[incomeValue];

        if (mapping) {
          const requiredWeight = mapping.weight;
          const requiredCaches = mapping.numberOfCaches;
          const requiredDistance = mapping.distance;

          const matchingDeposit = deposits.find(deposit => {
            return deposit.amount !== null &&
                  deposit.weight >= requiredWeight &&
                  deposit.caches >= requiredCaches &&
                  deposit.distance >= requiredDistance;
          });

          if (matchingDeposit) {
            selectedDeposit.value = matchingDeposit;
            await nextTick();

            weight.value = mapping.weight;
            numberOfCaches.value = mapping.numberOfCaches;
            distance.value = mapping.distance;

            executionDays.value = calculatedExecutionDays.value;
          } else {
            selectedDeposit.value = defaultDeposit;
          }
        }

        isAdjustingSliders.value = false;
      };

      const selectIncome = async (income) => {
        await adjustSlidersForIncome(income);
        selectedIncome.value = income;
      };

      const calculatedExecutionDays = computed(() => {
        const daysFromDistance = Math.floor(distance.value / 500);
        const daysFromCaches = Math.floor(numberOfCaches.value / 50);
        return Math.min(9, Math.max(1, daysFromDistance + daysFromCaches + 1));
      });

      watch([distance, numberOfCaches, weight], () => {
        if (
          selectedDeposit.value.amount !== null &&
          (distance.value > selectedDeposit.value.distance ||
          numberOfCaches.value > selectedDeposit.value.caches ||
          weight.value > selectedDeposit.value.weight)
        ) {
          selectedDeposit.value = defaultDeposit;
        }
      });

      watch([distance, numberOfCaches], () => {
        if (!isAdjustingSliders.value) {
          executionDays.value = calculatedExecutionDays.value;
        }
      });

      watch([distance, numberOfCaches, weight, executionDays], () => {
        if (!isAdjustingSliders.value) {
          selectedIncome.value = null;
        }
      });

      const minWeightAllowed = computed(() => {
        return distance.value >= 1000 ? 3 : 0.25;
      });

      watch([distance, weight], () => {
        if (weight.value < minWeightAllowed.value) {
          weight.value = minWeightAllowed.value;
        }
      });

      const costPerKm = computed(() => {
        if (distance.value <= 500) return distance.value * 50;
        if (distance.value <= 1000) return distance.value * 100;
        if (distance.value <= 2000) return distance.value * 150;
        return distance.value * 200;
      });

      const costPerCaches = computed(() => numberOfCaches.value * 7000);

      const costPerWeight = computed(() => weight.value * 12000);

      const totalCost = computed(() => {
        return costPerKm.value + costPerCaches.value + costPerWeight.value;
      });

      const bonus = computed(() => Math.ceil(totalCost.value * 0.05 / 1000) * 1000);

      const totalPaymentPerTrip = computed(() => {
        const totalWithBonus = totalCost.value + bonus.value;
        return Math.max(totalWithBonus, 80000);
      });

      const maxTripsPerMonth = computed(() => Math.floor(31 / executionDays.value));

      const monthlyIncome = computed(() => {
        const income = maxTripsPerMonth.value * totalPaymentPerTrip.value;
        return income.toLocaleString('ru-RU');
      });

      const tripIncome = computed(() => totalPaymentPerTrip.value.toLocaleString('ru-RU'));

      return {
        distance,
        numberOfCaches,
        weight,
        executionDays,
        deposits,
        selectedDeposit,
        monthlyIncome,
        tripIncome,
        incomeOptions,
        selectedIncome,
        updateDepositLimits,
        selectIncome,
        isAdjustingSliders,
        calculatedExecutionDays,
      };
    }
  };
</script>

<style scoped>
  @media (min-width: 900px) {
    #app {
      min-width: 900px;
    }
  }

  @media (min-width: 840px) {
    #app {
      max-width: 800px;
    }
  }

  .tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 220px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 6px 8px;
    border-radius: 4px;

    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -110px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltip .tooltiptext::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
</style>